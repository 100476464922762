import { Game } from './Game'
import { IBox } from './Intersect';
import { Virus } from './Virus'

export class Bullet {
  static bulletIndex = 0;
  elmBullet
  bulletBox: IBox;
  game: Game;
  position;
  startPos;
  velocity
  active = true;
  isHit = false;
  rotation = Math.random() * 180;
  loop;
  constructor(startPos: number, game: Game) {
    this.game = game
    this.elmBullet = document.createElement('div')
    this.elmBullet.setAttribute('class', 'bullet animated hideanimated image-bullet')
    this.elmBullet.setAttribute('style', 'top:' + startPos + 'px')

    this.game.addElement(this.elmBullet)
    this.startPos = startPos
    Bullet.bulletIndex++
    this.position = this.startPos + 30
    this.velocity = this.game.config().bulletStartVelocity
    this.elmBullet.style.transform = 'rotate(' + this.rotation + 'deg)'
    this.bulletBox = { top: this.position, left: 0, right: 0, bottom: 0 }

    this.loop = setInterval(() => {
      if (this.game.isPaused()) {
        return
      }
      this.updatePosition()
      this.checkVirusHit()
      this.checkPipeHit()
    }, this.game.config().framerate)

    this.updatePosition()
  }

  public isActive() {
    return this.active
  };

  public remove() {
    this.active = false
    clearInterval(this.loop)
    this.elmBullet.remove()
  };

  public updatePosition() {
    if (this.active === false) {
      return
    }
    if (this.game.gameState() !== 'game') {
      return
    }

    // Move bullet down
    this.velocity += this.game.config().gravity
    this.position += this.velocity

    // Bottom reached
    if (this.position > this.game.bounding().flyAreaHeight) {
      this.remove();
      return;
    }

    this.elmBullet.style.top = this.position + 'px'
    this.rotation += (this.velocity * 1.5)

    this.elmBullet.style.transform = 'rotate(' + this.rotation + 'deg)'
    this.elmBullet.style.transform = 'rotate(' + this.rotation + 'deg)'

    this.bulletBox.top = this.elmBullet.offsetTop
    this.bulletBox.left = this.elmBullet.offsetLeft
    this.bulletBox.right = this.bulletBox.left + this.game.config().bulletWidth
    this.bulletBox.bottom = this.bulletBox.top + this.game.config().bulletHeight
  };

  public getBox() {
    return this.bulletBox
  };

  public checkVirusHit() {
    if (!this.active) {
      return false
    }

    // Check for virusses on the way
    this.game.virusses().forEach((virus: Virus) => {
      if (!virus.isActive()) {
        return false
      }

      const virusBox = virus.getBox()

      if (this.bulletBox.right >= virusBox.left && this.bulletBox.left < virusBox.right && this.bulletBox.top < virusBox.bottom && this.bulletBox.bottom > virusBox.top) {
        console.error('HIT test');
        // Tell the virus it has been hit
        virus.hit()

        // Remove the bullet
        this.remove()
      }
    })
  };

  public checkPipeHit() {
    // Check if bounced to pipe
    this.game.pipes().forEach(pipe => {
      if (!pipe.isActive) {
        return
      }

      if (pipe.checkHit({
        top: this.bulletBox.top,
        right: this.bulletBox.right,
        bottom: this.bulletBox.bottom,
        left: this.bulletBox.left
      }, 'bullet')
      ) {
        this.remove()
      }
    })
  };
};
