import { Building } from './Building';
import { Bullet } from './Bullet';
import { patchConfig } from './Config';
import { Creator } from './Creator';
import { IConfig, GameConfig1, GameConfig2, GameConfigDefaults } from './GameConfig';
import { IBox, convertBoxToPolygon } from './Intersect';
import { Pipe } from './Pipe';
import { disableTouch, setEvent } from './Platform';
import { PlayAndWinPlatform } from './PlayAndWinPlatform';
import { PreLoader } from './PreLoader';
import { Virus } from './Virus';

export type GameState = 'init' | 'ready' | 'splash' | 'game' | 'score' | 'showsplash';

export class Game {
  private $config: IConfig;

  private $enableDebugMode = false;
  private $enableCreatorMode = false;

  // Used for css customization
  private $avatarSize = {
    playerWidth: 269,
    playerHeight: 180,
    playerImageHeight: -720,
    playerThrowPosition: 720
  }

  // Dynamic state
  private $state: {
    velocity: number,
    position: number,
    rotation: number,
    deploySpeed: number,
    activeLevel: number;
    sc: number;
    gameState: GameState;
    timer: number; // Used for endurence score
    levelStep: number; // Position in the level
    pauseState: boolean;
    virusSwapper: number;
    startTime: number;
    lastDeployTime: number;
  }

  private $levelData = [];

  private $bounding: {
    flyAreaHeight: number,
    groundTop: number,
    ceilBottom: number, // Position of ceiling for gameover
  }

  // Config or dynamic
  private $levels: Array<string> = []; // Map of objects in game

  // Active images
  private $pipes: Array<Pipe> = []; // Active pipes

  private $bullets = []; // Active bullets

  private $elm = {
    player: document.getElementById('player') as HTMLDivElement,
    flyArea: document.getElementById('flyarea') as HTMLDivElement,
    score: document.getElementById('bigscore') as HTMLDivElement
  }

  // loops
  private loopGameloop: number;
  private cleanLoop: number;
  private buildingTimeout: number;

  // Preload image area
  private $images = [];

  // Playenwin platform
  private pew: PlayAndWinPlatform;
  private creatorControler: Creator;
  private buildingControler: Building = new Building(this);
  private preLoader = new PreLoader(this);

  private $virusses: Array<Virus> = [] // Active virussed
  virusses() {
    return this.$virusses;
  }

  bounding() {
    return this.$bounding;
  }

  gameState() {
    return this.$state.gameState;
  }

  isPaused() {
    return this.$state.pauseState;
  }

  config(): IConfig {
    return this.$config;
  }

  pipes() {
    return this.$pipes;
  }

  addElement(object: HTMLDivElement) {
    this.$elm.flyArea.append(object);
  }

  constructor() {
    disableTouch(); // Disable all touch events for scaling etc.

    // If creatormode parameter is set, debug info will be shown
    if (this.$enableCreatorMode || window.location.search.search('creator') !== -1) {
      this.$enableCreatorMode = true;
      this.creatorControler = new Creator(this);
    }

    this.loadConfig();

    this.preloadImages()

    this.$bounding = {
      flyAreaHeight: document.getElementById('flyarea').offsetHeight,
      groundTop: document.getElementById('land').offsetTop,
      ceilBottom: document.getElementById('ceiling').offsetTop + document.getElementById('ceiling').offsetHeight
    }

    // Check debugmode. If turned on debug info will be shown
    if (window.location.search.search('debug') !== -1) {
      this.$enableDebugMode = true;
    }

    // Show creator area on screen
    if (this.$enableCreatorMode) {
      this.creatorControler.init();
    }

    // register touch and click events on document
    this.registerEvents();

    this.pew = new PlayAndWinPlatform(); // Load the playandwin platform

    // Register this game in the platform and wait for callback
    this.pew.init(this,
      () => {
        this.onPlatFormInit();
      }
    );
  };

  public setLevels(levels: Array<string>) {
    this.$levels = levels;
  }

  private onPlatFormInit() {
    this.showGame();
    this.readyToPlay();
  }

  private registerEvents() {
    // Handle space bar
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      // space bar!
      if (e.code === 'Space') {
        if (this.$state.gameState === 'game') {
          // Clicks are only triggered while playing
          this.screenClick();
        }
      }

      // Pause option is available but not working
      if (e.code === 'KeyP') {
        this.pause();
      }
    });

    // Register the main clickevent
    setEvent(document, (e: MouseEvent) => this.screenClick(e));
  }

  space() {
    if (this.$state.gameState === 'game') {
      // Clicks are only triggered while playing
      this.screenClick();
    }
  }

  private loadConfig() {
    const urlParams = new URLSearchParams(window.location.search);
    switch (urlParams.get('v')) {
      case '1':
        this.$config = GameConfig1;
        break;
      case '2':
        this.$config = GameConfig2;
        break;
      default:
        this.$config = GameConfigDefaults;
    }
    patchConfig(this.$config);

    this.$state = {
      activeLevel: 0, // Start in level 0
      deploySpeed: this.$config.deploySpeed, // Start speed
      position: this.$config.startPosition, // Start position
      rotation: 40, // Start rotation
      velocity: 0, // Start wihtout velocity?
      gameState: 'init', // Initial state
      levelStep: 0, // Initial step
      sc: 0, // Initial score
      timer: 0, // Initial timer
      pauseState: false, // Not paused
      virusSwapper: 0,
      lastDeployTime: (new Date()).getTime(),
      startTime: (new Date()).getTime()
    }

    // Load levels from config
    this.$levels = this.$config.levels;

    // Player boundingbox settings
    // Based on image of 900* 269px with 5 frames
    this.$avatarSize = {
      playerWidth: 269 * this.$config.playerScale,
      playerHeight: 180 * this.$config.playerScale,
      playerImageHeight: -720 * this.$config.playerScale,
      playerThrowPosition: 720 * this.$config.playerScale
    }

    this.patchCss();
  }

  private patchCss() {
    const sheet = document.createElement('style');
    // if creator mode, add anti cache headers

    const bulletStart = this.$config.bulletStartMoveX;
    const bulletEnd = 400;
    const bulletSpeed = 85 * 3;
    const bulletDuration = (bulletEnd - bulletStart) / bulletSpeed * 1000;

    sheet.innerHTML = this.backgroundImage('.image-ground', 'ground.png') +
      this.backgroundImage('.image-grass', 'grass.png') +
      this.backgroundImage('.image-lucht', 'lucht.png') +
      this.backgroundImage('.image-molen', 'molen.png') +
      this.backgroundImage('.image-stadion', 'stadion.png') +
      this.backgroundImage('.image-splash', 'splash.png') +
      this.backgroundImage('.image-koeman', 'koeman.png') +
      this.backgroundImage('.image-bullet', 'toiletrol.png') +
      this.backgroundImage('.image-corona', 'corona.png') +
      this.backgroundImage('.image-corona-alt', 'corona-alt.png') +
      this.backgroundImage('.image-corona-alt2', 'corona-alt2.png') +
      this.backgroundImage('.image-pijp-boven', 'pijp-boven.png') +
      this.backgroundImage('.image-pijp-beneden', 'pijp-beneden.png') + `

        #player {
          width: ` + this.$avatarSize.playerWidth + `px;
          height: ` + this.$avatarSize.playerHeight + `px;
        }
        #player.throw {
          background-position: 0px ` + this.$avatarSize.playerThrowPosition + `px;
        }

        @keyframes animBird {
          from { background-position: 0px 0px; }
          to { background-position: 0px ` + this.$avatarSize.playerImageHeight + `px; }
        }

        #gamescreen.start #player {
          left: -` + (this.$avatarSize.playerWidth + 10) + `px;
        }
        .bullet {
          max-height: ` + this.$config.bulletHeight + `px;
          max-width: ` + (this.$config.bulletWidth) + `px;
          height: ` + this.$config.bulletHeight + `px;
          width: ` + this.$config.bulletWidth + `px;
          sleft: ` + bulletEnd + `px;
          animation: animBullet ` + bulletDuration + `ms linear;
        }

        .virus {
         max-height: ` + this.$config.virusHeight + `px;
         max-width: ` + (this.$config.virusWidth) + `px;
         height: ` + this.$config.virusHeight + `px;
          width: ` + this.$config.virusWidth + `px;
        }

        @keyframes animBullet {
          0% { left: ` + this.$config.bulletStartMoveX + `px; }
          100% { left: ` + bulletEnd + `px; }
        }
        `
    ;
    document.body.appendChild(sheet);
  }

  private backgroundImage(className: string, filename: string) {
    const path = this.$config.assetUrl + this.$config.path;
    const cache = this.$enableCreatorMode ? '?' + Date.now() : '';
    return className + ' { background-image: url(\'' + path + '/' + filename + cache + '\')}';
  }

  private preloadImages() {
    const imageList = ['font_big_0.png', 'font_big_1.png', 'font_big_2.png', 'font_big_3.png', 'font_big_4.png', 'font_big_5.png', 'font_big_6.png', 'font_big_7.png',
      'font_big_8.png', 'font_big_9.png', 'corona.png', 'corona-alt.png', 'corona-alt2.png', 'molen.png', 'stadion.png', 'toiletrol.png', 'pijp-beneden.png', 'pijp-boven.png', 'koeman.png',
      'lucht.png', 'grass.png', 'ground.png', 'splash.png', 'splashtest.png'
    ];
    this.preLoader.setPath(this.$config.assetUrl + this.$config.path, this.$enableCreatorMode);
    this.preLoader.preload(imageList, () => {

    });
  }

  public play() {
    switch (this.$state.gameState) {
      case 'ready':
        this.showGame();
        this.showSplash();
        break;
      case 'score':
        this.setBigScore(true);
        this.showGame();
        this.showSplash();
        break;
    }
  }

  /**
   * SCREEN GAME
   */
  private showGame() {
    window.scrollTo(0, 0);

    this.$levelData = [];

    this.$state.levelStep = 0;
    this.$levels[this.$state.activeLevel].split(';').forEach(levelinfo => {
      this.$levelData.push(levelinfo.split(','));
    });
    this.$state.levelStep = this.$levelData[0][0];

    document.getElementById('gamecontainer').classList.remove('hidden');
    document.getElementById('gamecontainer').classList.remove('invisible');
    document.getElementById('gamescreen').classList.add('open');
    this.$state.gameState = 'ready';
    // // Show the click to begin
    // this.showSplash();
  }

  /**
  * SCREEN GAMEOVER
  */
  private showSplash() {
    // Prevent other events until ready
    this.$state.gameState = 'showsplash';

    // set the defaults (again)
    this.$state.velocity = 0;
    this.$state.position = this.$config.startPosition;
    this.$state.rotation = 30;
    this.$state.sc = 0;

    // Put player avatar in begin position
    this.updatePlayer();

    // Cleanup / all objects, bullets and buildings objects
    this.clearAll();

    // Make everything animated again
    document.querySelectorAll('.animated').forEach(elm => { elm.classList.remove('pauseAnimation'); });

    // Start animating the splash screen
    document.getElementById('gamescreen').classList.remove('start');
    setTimeout(() => {
      this.$state.gameState = 'splash';
      const avatarBox = this.getAvatarBox();
      this.showBox('playerboxje', avatarBox);
    }, 2000);
  }

  private readyToPlay() {
    this.pew.ready();
    this.showGame();
  }

  public replay() {

    if (this.$state.gameState !== 'ready') {
      return;
    }
    // this.setBigScore(true);
    this.showSplash();
  }

  private clearAll() {
    // clear out all the pipes if there are any
    this.$virusses.forEach(v => v.removeVirus());
    this.$pipes.forEach(v => v.remove());
    this.$bullets.forEach(v => v.remove());

    this.$pipes = [];
    this.$virusses = [];
    this.$bullets = [];

    this.cleanup();
  };

  /**
   * SCREEN GAME
   */
  private startGame() {
    if (this.$state.gameState === 'game') {
      return;
    }
    this.$state.gameState = 'game';

    // fade out the splash
    document.getElementById('splash').classList.add('invisible');

    // update the big score
    this.setBigScore();

    // start up our loops
    if (this.loopGameloop) {
      window.clearInterval(this.loopGameloop);
    }

    this.loopGameloop = window.setInterval(() => {
      this.gameloop();
    }, this.$config.framerate);

    if (this.cleanLoop) {
      window.clearInterval(this.cleanLoop);
    }

    this.cleanLoop = window.setInterval(() => {
      this.cleanup()
    }, 1000); // Do a cleanup every second

    // this.pew.gamestarted();

    // jump from the start!
    this.playerJump();
    this.$state.timer = (new Date()).getTime();
    // Start timers for deploy

    // Start deploying frames
    this.nextEvent();

    this.buildingControler.start(this.$config.deployFirstBuildingTime);

    this.$state.sc = 0;
  };

  private updatePlayer() {
    // rotation
    this.$state.rotation = Math.min((this.$state.velocity / 16) * 90, 90);

    // apply rotation and position
    this.$elm.player.style.transform = 'rotate(' + this.$state.rotation + 'deg)';
    this.$elm.player.style.top = this.$state.position + 'px';
  }

  private setEndurance() {
    if (this.$state.timer % this.$config.timeStep === 0 && this.$state.timer !== 0) {
      this.ps(this.$config.timeScore);
      this.$state.timer = 0;
    }
    this.$state.timer++;
  }

  private gameloop() {
    if (this.$state.pauseState) {
      return;
    }
    if (this.$state.gameState !== 'game') {
      return;
    }

    this.setEndurance();

    // update the player speed/position
    this.$state.velocity += this.$config.gravity;
    this.$state.position += this.$state.velocity;

    // update the player position
    this.updatePlayer();

    const avatarBox = this.getAvatarBox();

    // did we hit the ground?
    if (avatarBox.bottom >= this.$bounding.groundTop) {
      this.playerDead('ground');
      return;
    }

    // have they tried to escape through the ceiling? :o
    if (avatarBox.top <= this.$bounding.ceilBottom) {
      this.$state.position = 0;
      this.playerDead('ceiling');
      return;
    }

    // Walk over all pipes and check of is hit or passed for score
    this.$pipes.forEach(pipe => {
      if (this.$state.gameState === 'score') {
        // already dead. stop checking
        return;
      }
      if (pipe.checkHit(avatarBox, 'avatar')) {
        this.playerDead('pipe');
      }
    });

    // check if virus is hit by player
    this.$virusses.forEach(virus => {
      if (this.$state.gameState === 'score') {
        // already dead. stop checking
        return;
      }
      if (virus.checkHit(avatarBox)) {
        this.playerDead('virus');
      }
    });
    this.showBox('playerboxje', avatarBox);
  }

  private getAvatarBox() {
    // create the bounding box

    // Bounding box size is adjusted
    const rotateCorrection = 0; // (Math.sin(Math.abs(this.$state.rotation) / 90) * 8) // 8px per

    const boxwidth = this.$avatarSize.playerWidth - (this.$config.hitBoxMarginX * 2) - rotateCorrection;
    const boxheight = this.$avatarSize.playerHeight - (this.$config.hitBoxMarginY * 2);
    const boxLeft = this.$elm.player.offsetLeft + this.$config.hitBoxMarginX + this.$config.hitBoxMoveX;
    const boxTop = this.$elm.player.offsetTop + this.$config.hitBoxMarginY + this.$config.hitBoxMoveY;

    const response = {
      left: boxLeft,
      top: boxTop,
      right: boxLeft + boxwidth,
      bottom: boxTop + boxheight,
      rotation: this.$state.rotation,
      parentBox: {
        top: this.$elm.player.offsetLeft,
        bottom: this.$elm.player.offsetHeight + this.$elm.player.offsetTop,
        right: this.$elm.player.offsetLeft + this.$elm.player.offsetWidth,
        left: this.$elm.player.offsetLeft
      } as IBox
    } as IBox;

    return response;
  }

  private cleanup() {
    this.buildingControler.cleanup();

    for (let i = 0; i < this.$virusses.length; i++) {
      this.$virusses[i].clean();
      if (!this.$virusses[i].isActive()) {
        this.$virusses.splice(i, 1);
        return;
      }
    }

    let doMore = true;
    while (doMore) {
      doMore = false;
      for (let i = 0; i < this.$pipes.length; i++) {
        if (doMore) { continue; }
        this.$pipes[i].clean();
        if (!this.$pipes[i].isActive()) {
          this.$pipes.splice(i, 1);
          doMore = true;
          continue;
        }
      }
    }
    doMore = true;
    while (doMore) {
      doMore = false;
      for (let i = 0; i < this.$virusses.length; i++) {
        if (doMore) { continue; }
        this.$virusses[i].clean();
        if (!this.$virusses[i].isActive()) {
          this.$virusses.splice(i, 1);
          doMore = true;
          continue;
        }
      }
    }

    doMore = true;
    while (doMore) {
      doMore = false;
      for (let i = 0; i < this.$bullets.length; i++) {
        if (doMore) { continue; }
        if (!this.$bullets[i].isActive()) {
          this.$bullets.splice(i, 1);
          doMore = true;
          continue;
        }
      }
    }
  }

  private screenClick(e?: MouseEvent) {
    e?.preventDefault();
    e?.stopImmediatePropagation();

    if (this.$state.pauseState) {
      return;
    }

    switch (this.$state.gameState) {
      case 'game':
        this.playerJump();
        if (this.$config.enableBullets) {
          this.addBullet();
        }
        break;
      case 'splash':
        this.startGame();
        break;
    }
  }

  private playerJump() {
    this.$state.velocity = this.$config.jump;
  }

  private digitImg: Array<HTMLImageElement> = [];

  private setBigScore(erase: boolean = false) {
    // this.$elm.score.innerHTML = this.$state.sc.toString();
    if (!erase) {
      const digits = this.$state.sc.toString().split('');

      // const a = document.createElement('div');
      for (let i = 1; i <= digits.length; i++) {
        if (this.digitImg.length < i) {
          const img = document.createElement('img');
          this.digitImg.push(img);
          this.$elm.score.append(img);
        }
        this.digitImg[i - 1].setAttribute('src', this.$config.assetUrl + this.$config.path + '/font_big_' + digits[i - 1] + '.png');
        // img.setAttribute('src', this.$config.assetUrl + this.$config.path + '/font_big_' + digits[i] + '.png');
        // a.append(img);
      }
      // this.$elm.score.innerHTML = '';
      // this.$elm.score.append(a);
    } else {
      this.$elm.score.innerHTML = '';
      this.digitImg = [];
    }
  }

  public pause() {
    if (this.$state.pauseState) {
      document.querySelectorAll('.animated').forEach(elm => { elm.classList.remove('pauseAnimation'); });
      this.$state.pauseState = false;
      clearTimeout(this.buildingTimeout);
    } else {
      document.querySelectorAll('.animated').forEach(elm => { elm.classList.add('pauseAnimation'); });
      this.$state.pauseState = true;
      this.buildingControler.start(0); // Restart building spawn
    }
  }

  private playerDead(reason: string) {
    // it's time to change states. as of now we're considered ScoreScreen to disable left click/flying
    this.$state.gameState = 'score';

    // destroy our gameloops
    window.clearInterval(this.loopGameloop);
    window.clearInterval(this.cleanLoop);
    window.clearTimeout(this.buildingTimeout);

    this.loopGameloop = null;
    this.cleanLoop = null;

    // stop animating everything!
    document.querySelectorAll('.animated').forEach(elm => { elm.classList.add('pauseAnimation'); });

    // Hide bullets
    document.querySelectorAll('.bullet').forEach(elm => { elm.classList.add('invisible'); });

    // Animate a flash
    document.getElementById('gamecontainer').classList.add('deadflash');

    // Calculate end falling animation of player if not dead to the floor
    if (reason !== 'ground') {
      this.$elm.player.classList.add('dead');
      this.$elm.player.style.top = (this.$bounding.groundTop - (this.$avatarSize.playerWidth / 2)) + 'px';
      this.$elm.player.style.transform = 'rotate(60deg)';
    }

    // Take some time before showing the score screen
    setTimeout(() => {
      this.prepareGame();
    }, 750);

    // Call Google Analytics with the score
    // this.qliqqer.write(this.sc);
    this.pew.gameover(this.$state.sc);
  }

  private prepareGame() {
    // set the intitial state
    this.$state.velocity = 0;
    this.$state.position = this.$config.startPosition;
    this.$state.rotation = 30;
    this.$state.sc = 0;

    // Remove all buildings from document
    document.querySelectorAll('.building').forEach((e: Element) => {
      e.remove();
    });

    // Put player avatar in begin position
    this.updatePlayer();

    // Cleanup / all objects, bullets and buildings objects
    this.clearAll();
    this.showScore();
    this.setBigScore(true);
    this.pew.ready();
  }

  private showScore() {
    this.clearAll();
    // unhide us
    document.getElementById('gamecontainer').classList.remove('deadflash');
    document.getElementById('gamescreen').classList.add('start');
    document.getElementById('splash').classList.remove('invisible');
    this.$elm.player.classList.remove('dead');
  }

  /**
   * Add points to the score and update the score on the screen
   */
  public ps(p: number) {
    this.$state.sc += p;
    // this.qliqqer.set(this.sc);
    this.pew.updatescore(this.$state.sc);
    this.setBigScore();
  }

  private deployStep() {
    if (this.$state.pauseState) {
      return;
    }

    if (this.$state.gameState !== 'game') {
      return;
    }
    if (this.$state.levelStep >= this.$levelData.length) {
      this.$state.levelStep = 0;
      this.$state.deploySpeed = this.$state.deploySpeed * 0.9; // increase speed with 10%
    }

    const stepInfo = this.$levelData[this.$state.levelStep];
    this.$state.levelStep++;
    if (stepInfo[1]) {
      if (this.$config.enableVirus) {
        this.addVirus(stepInfo[1]);
      }
    }
    if (stepInfo[2]) {
      this.addPipe('upper', stepInfo[2]);
    }
    if (stepInfo[3]) {
      this.addPipe('lower', stepInfo[3]);
    }
  }

  nextEvent() {
    const stepTime = (new Date()).getTime();

    // Check deploy
    if ((stepTime - this.$state.lastDeployTime) > this.$state.deploySpeed) {
      this.$state.lastDeployTime = stepTime;
      this.deployStep();
    }
    if (this.$state.gameState === 'game') {
      requestAnimationFrame(() => this.nextEvent())
    }
  }

  private addPipe(pipeType, height) {
    if (pipeType === 'upper') {
      this.$pipes.push(new Pipe(pipeType, this.$bounding.flyAreaHeight / 100 * (height * 10), this));
    }
    if (pipeType === 'lower') {
      this.$pipes.push(new Pipe(pipeType, this.$bounding.flyAreaHeight / 100 * ((11 - height) * 10), this));
    }
  }

  private addVirus(height) {
    this.$state.virusSwapper++;
    let points: number;
    switch (this.$state.virusSwapper) {
      case 10:
        points = this.$config.virusScore3;
        break;
      case 5:
        points = this.$config.virusScore2;
        break;
      default:
        points = this.$config.virusScore1
    }
    // const points = (this.$state.virusSwapper === 10 || this.$state.virusSwapper === 5) ? this.$config.virusScore2 : ;
    this.$virusses.push(new Virus(this.$bounding.flyAreaHeight / 100 * ((height - 1) * 10), this.$state.virusSwapper, this, points));
    if (this.$state.virusSwapper === 10) {
      this.$state.virusSwapper = 0;
    }
  }

  private addBullet() {
    this.$elm.player.classList.add('throw');

    setTimeout(() => {
      this.$elm.player.classList.remove('throw');
    }, 100);

    this.$bullets.push(new Bullet(this.$state.position - this.$config.bulletStartMoveY, this));
  }

  public showBox(id: string, boxsize: IBox, classname = null) {
    if (!this.$enableDebugMode) {
      return;
    }
    if (boxsize.rotation !== undefined) {
      this.showPolygon(id, classname, boxsize);
      return;
    }
    let box = document.getElementById(id);
    if (box === null) {
      box = document.createElement('div');
      box.setAttribute('id', id);
      box.setAttribute('class', 'debugbox');
      if (classname) {
        box.classList.add(classname);
      }
      this.addElement(box as HTMLDivElement);
    }

    box.setAttribute('style', 'top: ' + boxsize.top + 'px; left: ' + boxsize.left + 'px; width: ' + (boxsize.right - boxsize.left) + 'px; height:' + (boxsize.bottom - boxsize.top) + 'px;');
  }

  showPolygon(id: string, classname: string, boxSize: IBox) {
    const poly = convertBoxToPolygon(boxSize);
    const size = poly.map(p => { return p.x + ',' + p.y; }).join(' ');
    const html = '<svg width="100%" height="100%" id="debugpoly' + id + '" class="debugPolygon"><polygon points="' + size + '" style="fill:none;stroke:red;stroke-width:2" /></svg>';

    let svg = document.getElementById('debugpoly' + id);
    if (svg) {
      this.$elm.flyArea.removeChild(svg);
    }

    const template = document.createElement('template');
    template.innerHTML = html;
    svg = template.content.firstChild as HTMLElement;
    this.$elm.flyArea.append(svg);

    // box.setAttribute('style', 'top: 0; left: ' + boxSize.left + 'px; width: ' + (boxSize.right - boxSize.left) + 'px; height:' + (boxSize.bottom - boxSize.top) + 'px;');
  }

  public removeBox(id) {
    if (document.getElementById(id)) {
      document.getElementById(id).remove();
    }
  }
}
