import { Game } from './Game';
import { doBoxesOverlap, IBox } from './Intersect';

export class Pipe {
  static pipeIndex = 0;
  id = 0;
  elmPipe: HTMLDivElement;
  pipeType: 'upper' | 'lower';
  height: number;
  active = true;
  game: Game;
  passed = false;;
  blocked = false;
  top: number;
  bottom: number;

  constructor(pipetype: 'upper' | 'lower', height: number, game: Game) {
    Pipe.pipeIndex++; // New static index for id
    this.id = +Pipe.pipeIndex;
    this.game = game;
    this.pipeType = pipetype;
    this.height = height;

    this.elmPipe = document.createElement('div');
    if (this.pipeType === 'upper') {
      this.elmPipe.setAttribute('class', 'pipe pipe_upper animated image-pijp-boven');
    } else {
      this.elmPipe.setAttribute('class', 'pipe pipe_lower animated image-pijp-beneden');
    }
    this.elmPipe.setAttribute('style', 'height: ' + this.height + 'px;');
    this.game.addElement(this.elmPipe);
    this.top = this.elmPipe.offsetTop;
    this.bottom = this.elmPipe.offsetTop + this.height;
  }

  public isActive() {
    return this.isActive;
  };

  public clean() {
    if (this.elmPipe.offsetLeft === -100) {
      this.remove();
    }
  };

  public checkHit(sourceBox: IBox, type?: 'bullet' | 'avatar') {
    if (this.game.isPaused()) {
      return;
    }
    if (!this.active) {
      return;
    }

    // var nextpipeupper = this.object.children(".pipe_upper");
    // var pipetop = nextpipeupper.offset().top + nextpipeupper.height();
    const pipeleft = this.elmPipe.offsetLeft - 2; // for some reason it starts at the inner pipes offset, not the outer pipes.

    const x1min = pipeleft;
    const x1max = pipeleft + this.game.config().pipeWidth;
    const y1min = this.top;
    const y1max = this.bottom;

    const x2min = sourceBox.left;
    const x2max = sourceBox.right;
    const y2min = sourceBox.top;
    const y2max = sourceBox.bottom;

    const pipeBox: IBox = {
      bottom: this.elmPipe.offsetTop + this.elmPipe.offsetHeight,
      left: pipeleft,
      right: pipeleft + this.elmPipe.offsetWidth,
      top: this.elmPipe.offsetTop
    }

    this.game.showBox('pipe' + this.id, {
      top: this.top,
      left: pipeleft,
      right: pipeleft + this.game.config().pipeWidth,
      bottom: this.bottom
    }, 'blue');

    // const result = (x1min < x2max && x2min < x1max && y1min < y2max && y2min < y1max);
    let result: boolean;
    if (type === 'avatar') {
      result = doBoxesOverlap(sourceBox, pipeBox);
      // doBoxesOverlap(sourceBox, pipeBox);
    } else {
      result = (x1min < x2max && x2min < x1max && y1min < y2max && y2min < y1max);
    }

    return result;
  };

  public remove() {
    this.game.removeBox('pipe' + this.id);
    this.elmPipe.remove();
    this.active = false;
  };
};
